(function($, document, window) {
	'use strict';

	$(function() {
		var page = 2;
		var isLoading = false;
		const container = $('.js-posts-container');
		const categoryFull = 'category-article__grid--full';
		const categoryLoading = 'category-article__grid--loading';
		const posts_per_page = load_more_params.posts_per_page;
		const category = container.data('category');

        $(window).on('load scroll resize', function () {
			if (isLoading) return;
			let topWindow = $(window).scrollTop();
			let heightWindow = $(window).height();
			let trigger = topWindow + ( heightWindow * 0.75 );

			$( container ).each( function() {
				let isFull = $( this ).hasClass( categoryFull );
				if ( !isFull ) {
					let sectionTop = $( this ).offset().top;
					let sectionBottom = sectionTop + $( this ).innerHeight() - 550;
					
					if ( sectionBottom < trigger ) {
						isLoading = true;
						container.addClass( categoryLoading );
						var data = {
							'action': 'load_more_posts',
							'security': load_more_params.nonce,
							'page': page,
							'category': category,
							'posts_per_page': posts_per_page,
						};
						$.post(load_more_params.ajaxurl, data, function(response) {
							if (response.success && response.data.posts) {
								container.append(response.data.posts);
								page++;
								if (response.data.remaining <= 0) {
									container.addClass(categoryFull);
								}
							} else {
								container.addClass(categoryFull);
							}
							container.removeClass( categoryLoading );
							isLoading = false;
						});
					}
				}
			});
		});
	});
})(jQuery, document, window);
